<template>
  <div>
    <div class="card border-0 shadow-sm cursor-pointer" @click="goTo">
      <div class="card-body">
        <i class="far text-success fa-2x mb-3" :class="icon"></i>
        <h5 class="text-success text-bold fw-bold mb-0">{{ title }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "icon", "route"],
  methods: {
    goTo() {
      this.$router.push(this.route);
    },
  },
};
</script>

<style>
</style>